import { Component, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CleanupService } from './core/services/cleanup.service';
import { LoaderService } from './modules/loaders';
import { MessagesBusService } from './modules/messages';
import { LanguageService } from './i18n';
import { NavigationStart, Router } from '@angular/router';
import { delay, distinctUntilChanged, filter, map, tap } from 'rxjs';
import { WindowService } from '@library';
import { TranslateService } from '@ngx-translate/core';
import { PushNotificationsFacade } from './store/push-notifications';
import { BackButtonNativeService } from './shared/services/back-button.native.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    // needs to be initiated
    readonly router = inject(Router);
    readonly windowService = inject(WindowService);
    readonly cleanupService = inject(CleanupService);
    readonly loaderService = inject(LoaderService);
    readonly messagesService = inject(MessagesBusService);
    readonly languageService = inject(LanguageService);
    readonly translateService = inject(TranslateService);
    readonly backButtonNativeService = inject(BackButtonNativeService);
    readonly pushNotificationsFacade = inject(PushNotificationsFacade);
    readonly title = inject(Title);

    ngOnInit() {
        this.title.setTitle(this.translateService.instant('title'));

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                map((event) => event.url),
                map((url) => {
                    const skipUrl = '(mobile:menu)';

                    if (url.endsWith(skipUrl)) {
                        return url.slice(0, -skipUrl.length);
                    }
                    return url;
                }),
                distinctUntilChanged(),
                delay(50),
                tap(() => this.windowService.scrollToTop()),
            )
            .subscribe();

        this.backButtonNativeService.init();
    }

    state$ = this.pushNotificationsFacade.state$;
    token$ = this.pushNotificationsFacade.token$;

    onClick() {
        this.pushNotificationsFacade.register();
    }

    onUnregisterClick() {
        this.pushNotificationsFacade.unregister();
    }
}
